import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import { FaCheck } from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    sla: file(relativePath: { eq: "umowa-sla-questy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Aura = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Doradzamy w wyborze umowy SLA"
      headline="Czy zapewniasz swojej firmie ciągłość istotnych procesów biznesowych?"
    >
      <SEO
        title="Umowa SLA"
        description="Umowa SLA ✅ Doradzamy w wyborze umowy SLA • Questy - Twój biznes wygrywa"
        keywords={["systemy CRM"]}
      />

      <Columns>
        <div>
          <h3> Czym jest umowa SLA? </h3>
          <p>
            Umowa SLA{" "}
            <strong>
              (Service Level Agreement) to umowa o świadczeniu usług, których
              celem jest zapewnienie ciągłości istotnych procesów biznesowych
            </strong>
            . Korzystając w firmie z systemów IT, często nie myślimy o
            podpisaniu umowy na kompleksową opiekę nad systemem. Często też
            uważamy to za zbędny wydatek.
            <strong>
              {" "}
              Tymczasem to właśnie brak umowy SLA generuje nieprzewidziane i
              często wielokrotnie wyższe koszty, związane z utratą ciągłości
              pracy systemu i brakiem procedur wyjścia z takiej sytuacji
            </strong>
            . Chodzi tu nie tylko o straty finansowe, ale też nierzadko takie,
            które są trudniejsze do odrobienia np. utrata wiarygodności u
            klientów.
          </p>
        </div>
        <div>
          <h3> Usługi wynikające z umowy SLA to przede wszystkim: </h3>
          <p>
            <ul>
              <li>
                {" "}
                monitoring systemu i modułu synchronizacji oraz działania
                zapobiegające przestojom w pracy systemu,
              </li>
              <li> możliwość zgłaszania incydentów,</li>
              <li>
                {" "}
                określony czas na rozwiązanie incydentu krytycznego (np. brak
                dostępności systemu),
              </li>
              <li>
                {" "}
                gwarancja dostępności specjalistów, gotowych do rozwiązywania
                zgłoszonych incydentów w określonym czasie,
              </li>
              <li> dostęp do aktualizacji systemu i jej instalacji,</li>
              <li> 5 porad helpdesk w miesiącu.</li>
            </ul>
          </p>
        </div>
      </Columns>
      <br />
      <br />
      <GatsbyImage
        image={data.sla.childImageSharp.gatsbyImageData}
        style={{
          width: "100%",
          margin: "0 auto",
          maxWidth: 1000,
        }}
        alt="partnerzy aermec"
      />
      <br />
      <br />
      <center>
        <p>
          <strong>
            Wsparcie systemu to bardzo ważna rzecz, gdyż w praktyce jest to
            jedyny skuteczny sposób, żeby zagwarantować dostępność systemu na
            wymaganym poziomie. Oszczędności w tym zakresie są pozorne i mogą
            spowodować utratę zysków i zaufania klientów.
          </strong>
        </p>
      </center>
      <br />
      <br />
      <h3> Warto odpowiedzieć sobie na następujące pytania:</h3>
      <p>
        <ol>
          <li>
            {" "}
            Czy możemy sobie pozwolić na kilkudniową przerwę w pracy systemu?{" "}
          </li>
          <li>
            {" "}
            Ile stracimy, jeżeli nasi klienci przez kilka (kilkanaście) dni nie
            będą mogli złożyć zamówienia na platformie?{" "}
          </li>
          <li>
            {" "}
            Ile stracimy, jeżeli bez systemu nie będziemy mogli przyjąć
            zgłoszenia serwisowego i szybko zareagować na nowe zlecenie?{" "}
          </li>
          <li>
            {" "}
            Ile stracimy, jeżeli bez systemu pracownicy nie będą mieli dostępu
            do ważnych informacji, potrzebnych do realizacji sprzedaży?{" "}
          </li>
        </ol>
      </p>
      <p>
        <strong>
          {" "}
          Jeżeli nie mamy podpisanej umowy SLA — nie mamy gwarantowanego czasu
          reakcji na rozwiązanie problemu
        </strong>
        . Czasem może to potrwać nawet kilka lub kilkanaście dni, do momentu aż
        dostawca podejmie próbę rozwiązania incydentu. Co więcej dostawca nie
        musi się zająć takim zgłoszeniem.
      </p>
    </FunctionPageLayout>
  );
};

export default Aura;
